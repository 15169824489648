import p54 from "./54";
import p55 from "./55";
import p56 from "./56";
import p57 from "./57";
import p58 from "./58";
import p59 from "./59";
// import p100 from "./100";
// import p101 from "./101";
// import p102 from "./102";
// import p103 from "./103";

const pageQuestions = {
  4: p54,
  5: p55,
  6: p56,
  7: p57,
  8: p58,
  9: p59,
  // 100: p100,
  // 101: p101,
  // 102: p102,
  // 103: p103,
};

export default pageQuestions;
